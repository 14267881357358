import clsx from 'clsx';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { get as _get, find as _find } from 'lodash'
import { styled } from '@mui/material/styles';
import MintSBTSvg from 'assets/svg/mintsbt.svg'
import { Grid, Box, Toolbar, Typography, FormControl, FormLabel, List, ListItem, ListItemText } from '@mui/material';
import Button from 'components/Button';
import IconButton from "components/IconButton";
import Table from '@mui/material/Table';
import TextInput from 'components/TextInput';
import exportBtn from 'assets/svg/exportBtn.png'
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';
import TableCell from '@mui/material/TableCell';
import Drawer from '@mui/material/Drawer';
import AddIcon from '@mui/icons-material/Add';
import TableContainer from '@mui/material/TableContainer';
import CurrencyInput from "components/CurrencyInput"
import Switch from "components/Switch";
import TableHead from '@mui/material/TableHead';
import CloseSVG from 'assets/svg/close.svg'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import SETTINGS_SVG from 'assets/svg/settings.svg'
import USER_SVG from 'assets/svg/user-icon.svg'
import LINK_SVG from 'assets/svg/ico-link.svg'
import { useEffect, useMemo, useState } from 'react';
import axiosHttp from 'api'
import FullScreenLoader from 'components/FullScreenLoader';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { beautifyHexToken } from 'utils';
import palette from 'theme/palette';
import MemberListSVG from 'assets/svg/member-list.svg'
import EmailSVG from 'assets/svg/email.svg'
import TelegramSVG from 'assets/svg/telegram.svg'
import DiscordSVG from 'assets/svg/discord.svg'
import GithubSVG from 'assets/svg/githubicon.svg'
import { ethers } from "ethers";
import { SupportedChainId } from 'constants/chain';
import useMintSBT from 'hooks/useMintSBT';
import { usePrevious } from 'hooks/usePrevious';
import { useAppSelector } from 'hooks/useAppSelector';
import { useWeb3Auth } from 'services/web3Auth';
import { CHAIN_INFO } from 'constants/chainInfo';
import { USDC } from 'constants/tokens';
import Avatar from 'components/Avatar';
import moment from 'moment';
import XlsxUpload from "components/XlsxUpload"
import Dropzone from "components/Dropzone";
import CloseIcon from '@mui/icons-material/Close';
import HeaderLogo from 'components/HeaderLogo';
import SwitchChain from 'components/SwitchChain'
import { LeapFrog } from '@uiball/loaders';
import useBiconomyGasless from 'hooks/useBiconomyGasless';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setActiveContract, setDAOListAction } from 'store/actions/dao';
//@ts-ignore
import CsvDownloadButton from 'react-json-to-csv'
import useRole from 'hooks/useRole';
import theme from 'theme';

const useStyles = makeStyles((theme: any) => ({
    root: {
        paddingBottom: 60,
        // paddingTop: theme.spacing(6)
    },
    title: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '30px !important',
        lineHeight: '33px !important',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#76808D'
    },
    description: {
        fontStyle: "italic",
        fontWeight: 400,
        fontSize: "14px",
        maxWidth: 200,
        lineHeight: "18px",
        letterSpacing: "-0.011em",
        color: "rgba(118, 128, 141, 0.5) !important"
    },
    subtitle: {
        fontSize: '14px !important',
        fontWeight: '500',
        lineHeight: '19px !important',
        color: 'rgba(27, 43, 65, 0.5)'
    },
    paperDetails: {
        background: '#FFFFFF',
        padding: '26px 22px 8px 0px !important',
        //boxShadow: '3px 5px 4px rgba(27, 43, 65, 0.05), -3px -3px 8px rgba(201, 75, 50, 0.1) !important',
        borderRadius: '5px',
    },
    tokenName: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '22px !important',
        lineHeight: '25px !important',
        marginLeft: '16px !important',
        color: '#76808D !important'
    },
    tokenSupply: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px !important',
        lineHeight: '25px !important',
        marginLeft: '16px !important',
        color: '#76808D !important'
    },
    paperDetailsSocial: {
        background: '#FFFFFF',
        //padding: '26px 22px 30px !important',
        //boxShadow: '3px 5px 4px rgba(27, 43, 65, 0.05), -3px -3px 8px rgba(201, 75, 50, 0.1) !important',
        borderRadius: '5px'
    },
    socialText: {
        fontStyle: 'italic',
        fontWeight: 400,
        marginTop: '8px !important',
        fontSize: '14px !important',
        lineHeight: '16px !important',
        color: 'rgba(118, 128, 141, 0.5) !important',
    }
}));


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<MuiAppBarProps>(({ theme }) => ({
    height: 107,
    boxShadow: 'inherit',
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));


export default () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { contractId } = useParams()
    const dispatch = useAppDispatch()
    const { chainId, account } = useWeb3Auth()
    // @ts-ignore
    const { user } = useSelector(store => store.session)
    // @ts-ignore
    const { daoList, contract, dao } = useSelector(store => store.dao)
    const { myRole } = useRole(dao, account)

    // const [contract, setContract] = useState<any>(null)
    const [showDrawer, setShowDrawer] = useState<boolean>(false)
    const [gaslessConfigLoading, setGaslessConfigLoading] = useState<boolean>(false)
    const [updateContractLoading, setUpdateContractLoading] = useState<boolean | null>(null)
    const [tokens, setTokens] = useState<any>([])
    const [errors, setErrors] = useState<any>({})
    const [networkError, setNetworkError] = useState<any>(null)
    const [members, setMembers] = useState<any>([])
    const [fillGasLoading, setFillGasLoading] = useState<any>(false)
    const [gasDeposit, setGasDeposit] = useState<any>(0)
    const [mintEstimate, setMintEstimate] = useState<any>(null);
    const [fillGasError, setFillGasError] = useState<any>(false)
    const [discountPlaceholder, setDiscountPlaceholder] = useState<any>({ code: null, percentage: null })
    const { updateContract } = useMintSBT(_get(contract, 'address', ''), _get(contract, 'version', ''), +contract?.chainId)
    const { initBiconomyGasless, gasBalance, fillGas } = useBiconomyGasless(contract?.chainId)

    //const [orgData, setOrgData] = useState<any>(null);

    const [refillPrice, setRefillPrice] = useState<any>({
        token: "0x0000000000000000000000000000000000000000",
        value: 5
    })

    const [state, setState] = useState<any>({
        symbol: null,
        whitelisted: false,
        whitelist: {
            members: [],
            discounts: [],
            inviteCodes: []
        },
        discountCodes: [],
        contact: [],
        priced: false,
        treasury: "0x0000000000000000000000000000000000000000",
        logo: null,
        gasless: false,
        gasConfig: null,
        price: {
            token: "0x0000000000000000000000000000000000000000",
            value: 0
        }
    })

    const handleContactChange = (key: string) => {
        setState((prev: any) => {
            return {
                ...prev,
                contact: prev?.contact.indexOf(key) > -1 ? prev.contact.filter((c: string) => c !== key) : [...prev?.contact, key]
            }
        })
    }

    useEffect(() => {
        if(!mintEstimate && contract && chainId && +contract?.version >= 2) {
            axiosHttp.post(`utility/estimate-mint-gas`, {
                chainId,
                address: contract?.address,
                abi: require('abis/SBT.json')
            })
            .then((res: any) => {
                setMintEstimate(res.data)
            })
        }
    }, [contract, chainId, mintEstimate])

    useEffect(() => {
        if (contract && showDrawer) {
            setErrors({})
            setState((prev: any) => {
                return {
                    ...prev,
                    symbol: contract?.token,
                    contact: contract?.contactDetail,
                    logo: contract?.image,
                    gasless: contract?.gasless,
                    gasConfig: contract?.gasConfig,
                    whitelisted: contract?.whitelisted,
                    discountCodes: contract?.discountCodes || [],
                    priced: contract?.mintPrice && contract?.mintPrice !== "" && parseFloat(contract?.mintPrice) > 0 ? true : false,
                    treasury: contract?.mintPrice && contract?.mintPrice !== "" && parseFloat(contract?.mintPrice) > 0 ? contract?.treasury : "0x0000000000000000000000000000000000000000",
                    price: {
                        token: _get(contract, 'mintPriceToken', '0x0000000000000000000000000000000000000000'),
                        value: contract?.mintPrice && contract?.mintPrice !== "" && parseFloat(contract?.mintPrice) > 0 ? parseFloat(contract?.mintPrice) : "0"
                    }
                }
            })
        }
    }, [contract, showDrawer])

    useEffect(() => {
        if (contract && contract?.chainId) {
            setTokens([
                {
                    label: CHAIN_INFO[contract?.chainId]?.nativeCurrency?.symbol,
                    value: process.env.REACT_APP_NATIVE_TOKEN_ADDRESS,
                    decimals: CHAIN_INFO[contract?.chainId]?.nativeCurrency?.decimals
                },
                {
                    label: _get(USDC, `[${contract?.chainId}].symbol`),
                    value: _get(USDC, `[${contract?.chainId}].address`),
                    decimals: _get(USDC, `[${contract?.chainId}].decimals`),
                }
            ])
        }
    }, [contract])

    const contractToken = useMemo(() => {
        if (contract && tokens) {
            console.log("tokens", tokens)
            return _find(tokens, t => t?.value?.toLowerCase() === contract?.mintPriceToken?.toLowerCase())
        }
    }, [tokens, contract])


    const prevState: any = usePrevious(state)

    const renderIcons = (attributes: any) => {
        return (
            <Box display="flex" flexDirection="row" alignItems="center">
                {
                    attributes.map((attr: any) => {
                        if (attr.value && ['Email', 'Github', 'Discord', 'Telegram'].indexOf(attr.trait_type) > -1) {
                            return (
                                <Tooltip placement="top" title={attr.value}>
                                    <Box mx={0.5} display="flex" alignItems="center" justifyContent="center" style={{ width: 40, height: 40, backgroundColor: '#EEE' }}>
                                        <img style={{ width: 24, height: 24 }} src={
                                            attr.trait_type === 'Email' ? EmailSVG :
                                                attr.trait_type === 'Github' ? GithubSVG :
                                                    attr.trait_type === 'Discord' ? DiscordSVG :
                                                        attr.trait_type === 'Telegram' ? TelegramSVG : ''
                                        } />
                                    </Box>
                                </Tooltip>
                            )
                        }
                        return null
                    })
                }
            </Box>
        )
    }

    const isAddressValid = (holderAddress: string) => {
		const ENSdomain = holderAddress.slice(-4);
		if (ENSdomain === ".eth") {
			return true;
		} else {
			const isValid: boolean = ethers.utils.isAddress(holderAddress);
			return isValid;
		}
	};

    const handleInsertWallets = async (data: Array<{ name: string, address: string }>) => {
		try {
			let validMembers = [];
			let mem: any = {}
			if (data.length > 0) {
				const noHeader = _find(Object.keys(data[0]), key => isAddressValid(key))
				if (noHeader) {
					Object.keys(data[0]).map((key: any) => {
						if (isAddressValid(key))
							mem.address = key
						else
							mem.name = key
					})
				}
				let newData = data;
				if (Object.keys(mem).length > 0)
					newData = [...newData, mem]
				for (let index = 0; index < newData.length; index++) {
					let preParseMember: any = newData[index];
					let member: any = {}
					Object.keys(preParseMember).map((key: any) => {
						if (isAddressValid(preParseMember[key]))
							member.address = preParseMember[key]
						else
							member.name = preParseMember[key]
					})
                    validMembers.push({ ...member, role: 'role2' });
				}
			}
            console.log("validMembers", validMembers)
            setMembers(validMembers)
		} catch (e) {
            console.log(e)
		}
	}

    const handleInsertDiscountCode = async (data: { code: string, percentage: number }) => {
        setErrors({})
        if(data?.code && data?.percentage && data?.code !== "" && data?.percentage !== 0) {
            const exists = _find(state?.discountCodes, c => c.code === data?.code)
            if(!exists) {
                let currNPMembers = [...state?.discountCodes, data];
                setState((prev: any) => { return { ...prev, discountCodes: currNPMembers } })
                setDiscountPlaceholder({ code: "", percentage: "" })
                setErrors({})
            } else {
                setErrors((prev: any) => { return { ...prev, code: 'Code already exists' } })
                setTimeout(() => setErrors({}), 2000)
            }
        } else {
            setErrors((prev: any) => { return { ...prev, code: 'Please enter valid Code/Percentage' } })
            setTimeout(() => setErrors({}), 2000)
        }
    }

    const saveChanges = async () => {
        try {
            let err = {}
            if (state?.priced && (!state?.treasury || state?.treasury === "0x0000000000000000000000000000000000000000")) {
                err = {
                    ...err,
                    treasury: 'Enter valid wallet'
                }
            }
            if (!state?.logo) {
                err = {
                    ...err,
                    logo: 'Upload valid image'
                }
            }
            if (state?.priced && !state?.price?.value || state?.price?.value === "") {
                err = {
                    ...err,
                    price: 'Enter valid price'
                }
            }
            if (Object.keys(err).length > 0)
                return setErrors(err)
            setUpdateContractLoading(true)
            let res: any = { contactDetail: state?.contact }
            console.log(contract?.whitelisted , state?.whitelisted)
            console.log((contract?.mintPrice && contract?.mintPrice !== "" && parseFloat(contract?.mintPrice) > 0 ? true : false), state?.priced)
            console.log(contract?.mintPriceToken, state?.price?.token)
            console.log(contract?.treasury, "--", state?.treasury)
            if(
                (contract?.whitelisted !== state?.whitelisted) ||
                ((contract?.mintPrice && contract?.mintPrice !== "" && parseFloat(contract?.mintPrice) > 0 ? true : false) !== state?.priced) ||
                (contract?.mintPrice !== `${state?.price?.value ? state?.price?.value : "0"}`) ||
                (contract?.mintPriceToken !== state?.price?.token) ||
                (contract?.treasury !== state?.treasury)
            ) {
                console.log(+contract?.chainId, chainId)
                if(+contract?.chainId !== +chainId) {
                    setUpdateContractLoading(false)
                    console.log("Wrong chain..")
                    return toast.custom(t => <SwitchChain t={t} nextChainId={+contract?.chainId} />)
                }
                res = await updateContract(state?.treasury, state?.price?.value.toString(), state?.price?.token, state.whitelisted)
            }
            await axiosHttp.patch(`contract/${contract?.address}`, { ...res, contactDetail: state?.contact, image:  state?.logo, gasless: state?.gasless, token: state?.symbol, name: `${state?.symbol} SBT`, discountCodes: state?.discountCodes })
                .then(result => {
                    axiosHttp.get(`dao/sbt-dao`)
                    .then(async res => {
                        dispatch(setDAOListAction(res.data))
                        const act = _find(res.data, dl => dl?.sbt?.address === contract?.address)
                        console.log("ACT", act)
                        dispatch(setActiveContract(act))
                        if(members.length > 0)
                           await axiosHttp.patch(`dao/${act?.url}/add-member-list`, { list: members })
                    })
                    // axiosHttp.get(`contract?chainId=${chainId}`).then(res => {
                    //     setContract(res.data[res.data.length - 1])
                    //     axiosHttp.get(`contract/getContractDao/${res.data[res.data.length - 1]._id}`)
                    //         .then(async (res) => {
                    //             if (res) {
                    //                 if(members.length > 0)
                    //                     await axiosHttp.patch(`dao/${res.data?.url}/add-member-list`, { list: members })
                    //                 setOrgData(res.data);
                    //             }
                    //         })
                    //     setShowDrawer(false)
                    // })
                })
                .finally(() => setUpdateContractLoading(false))
        } catch (e) {
            setUpdateContractLoading(false)
            console.log(e)
            setNetworkError(e)
            setTimeout(() => setNetworkError(null), 3000)
        }
    }

    const getGasBalance = () => {
        return gasBalance({ apiKey: contract?.gasConfig?.apiKey })
        .then(res => { 
             console.log("GAS_BALANCE", res.data) 
             setGasDeposit(res?.data?.dappGasTankData?.effectiveBalanceInStandardForm || 0)
             return res;
         })
    }

    useEffect(() => {
        if(contract && contract?.gasless && contract?.gasConfig) {
            getGasBalance()
        }
    }, [contract])

    const earnings = useMemo(() => {
        if (contract && contract?.metadata && contract?.metadata.length > 0) {
            return contract.metadata.reduce((a: any, b: any) => a + parseFloat(_get(_find(b.attributes, bb => bb.trait_type === 'Price'), 'value', 0)), 0)
        }
        return 0
    }, [contract])

    const handleGaslessChange = async (value: boolean) => {
        if(value === true) {
            if(!contract?.gasConfig) {
                setGaslessConfigLoading(true)
                try {
                    const response  = await initBiconomyGasless({ dappName: contract?.name, chainId, contract: contract?.address, version: +contract?.version })
                    await axiosHttp.patch(`contract/${contract?.address}`, { gasless: true, gasConfig: response })
                    axiosHttp.get(`dao/sbt-dao`)
                    .then(async res => {
                        dispatch(setDAOListAction(res.data))
                        const act = _find(res.data, dl => dl?.sbt?.address === contract?.address)
                        console.log("ACT", act)
                        dispatch(setActiveContract(act))
                    })
                    setGaslessConfigLoading(false)
                } catch (e) {
                    setGaslessConfigLoading(false)
                }
            }
        }
        setState((prev:any) => { return { ...prev, gasless: value } })
    }

    const handleFillGas = async () => {
        try {
            console.log(+refillPrice?.value)
            if(+refillPrice?.value < 5){
                setFillGasError("Minimum Deposit Amount: 5 MATIC")
                setTimeout(() => setFillGasError(null), 3000)
                return;
            }
            setFillGasLoading(true)
            const receipt = await fillGas({ fundingKey: state?.gasConfig?.fundingKey, amount: refillPrice?.value || 0 })
            await getGasBalance()
            setFillGasLoading(false)
        } catch (e) {
            setFillGasError(_get(e, 'data.message', ''))
            setTimeout(() => setFillGasError(null), 3000)
            setFillGasLoading(false)
        }
    }

    const exportableData = useMemo(() => {
        const data = []
        if(contract?.metadata) {
            for (let index = 0; index < contract?.metadata.length; index++) {
                const metadata: any = contract?.metadata[index];
                let obj: any = {}
                obj["tokenId"] = metadata.id;
                obj["wallet"] = _get(_find(metadata?.attributes, (m: any) => m.trait_type === "Wallet Address/ENS Domain"), 'value', '');
                obj["price"] = `${parseFloat(_get(_find(metadata.attributes, aa => aa.trait_type === 'Price'), 'value', 0))}`;
                obj["token symbol"] = contractToken?.label;
                for (let index = 0; index < metadata?.attributes.length; index++) {
                    const attr = metadata?.attributes[index];
                    if (['Email', 'Github', 'Discord', 'Telegram'].indexOf(attr.trait_type) > -1) {
                        if(attr.value)
                            obj[attr.trait_type] = attr.value
                    }
                }
                const d = contract?.contactDetail.filter((c:any) => ['email', 'discord', 'telegram', 'github'].indexOf(c) === -1)
                for (let index = 0; index < d.length; index++) {
                    const element = d[index];
                    obj[_get(_find(metadata?.attributes, aa => aa.trait_type === element), 'trait_type', '')] = `${_get(_find(metadata?.attributes, aa => aa.trait_type === element), 'value', '')}`
                }
                obj['mint_date'] = moment(metadata.createdAt).format('MM/YY') + " " + moment(metadata.createdAt).format('HH:MM')
                data.push(obj)
            }
        }
        return data
    }, [contract, contractToken])

    if (!contract) {
        return <FullScreenLoader />
    }

    return (
        <Grid container className={classes.root}>
            {/* <Box sx={{ mt:5 }} display="flex" alignItems="flex-start" justifyContent={"flex-start"} style={{ width: '100%', height: '100px' }}>
                <Box>
                    <Typography sx={{ marginLeft: "20px" }} className={classes.title}>{orgData ? orgData.name : ''}</Typography>
                    <Typography sx={{ marginLeft: "20px", maxWidth: 800 }} className={classes.subtitle}>{orgData ? orgData.description : ''}</Typography>
                </Box>
            </Box> */}
            <Grid item sm={12} sx={{ mt: 0 }} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <Box>
                                <img src={_get(contract, 'image')} style={{ backgroundColor: "rgba(234, 100, 71, 0.1)", objectFit: 'contain', height: '168px', width: '168px', borderRadius: 10 }} />
                                </Box>
                                <Box style={{ width: '100%' }}>
                                    <Box mx={2} py={4} px={3} style={{ borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: 50, backgroundColor: '#FFF' }}>
                                        <Typography style={{ flexGrow: 1, fontSize: 26, fontWeight: 700 }}>{_get(contract, 'token')}</Typography>
                                        <Box>
                                            <IconButton onClick={() => {
                                                navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/mint/${_get(contract, 'address', '')}`);
                                                toast.success('Copied to clipboard')
                                            }} style={{ marginRight: 12 }}>
                                                <img src={LINK_SVG} />
                                            </IconButton>
                                            <IconButton onClick={() => setShowDrawer(true)} sx={{ marginleft: 1 }}>
                                                <img src={SETTINGS_SVG} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    {/* <Box mx={2} mt={0.5} p={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 5, width: '100%', backgroundColor: '#FFF'  }}>
                                    <Typography style={{ fontSize: 16, fontWeight: 400 }}>Price</Typography>
                                    <Typography style={{ marginTop: 16, fontSize: 18, fontWeight: 700 }}>{ _get(contract, 'mintPrice', 0) } { contractToken?.label }</Typography>
                                </Box> */}
                                    <Box mx={2} mt={0.5} px={3} style={{ display: 'flex', flexDirection: 'row', borderRadius: 5, width: '100%', background: '#fff' }}>
                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', flex: 1, height: 103 }}>
                                            <Typography style={{ fontSize: 16, fontWeight: 400 }}>Price</Typography>
                                            <Typography style={{ marginTop: 16, fontSize: 18, fontWeight: 700 }}>{_get(contract, 'mintPrice', 0)} {contractToken?.label}</Typography>
                                        </Box>
                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', flex: 1, height: 103 }}>
                                            <Typography style={{ fontSize: 16, fontWeight: 400 }}>Sales</Typography>
                                            <Typography style={{ marginTop: 16, fontSize: 18, fontWeight: 700 }}>{_get(contract, 'metadata', []).length} Sold</Typography>
                                        </Box>
                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', flex: 1, height: 103 }}>
                                            <Typography style={{ fontSize: 16, fontWeight: 400 }}>Earnings</Typography>
                                            <Typography style={{ marginTop: 16, fontSize: 18, fontWeight: 700 }}>{earnings.toFixed(4)} {contractToken?.label}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item mt={3} sm={12}>
                <Box sx={{ flexGrow: 1 }}>
                    <Toolbar
                        style={{
                            borderRadius: 5,
                            padding: '20px 22px 15px',
                            background: '#FFF'
                        }}
                    >
                        <Typography variant="h6"
                            style={{
                                color: '#76808D',
                                fontSize: 22,
                                fontWeight: 400,
                                flexGrow: 1
                            }}
                            component="div">Minting History</Typography>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <img src={MemberListSVG} />
                            <Typography sx={{ ml: 1, mr: 2 }} style={{ fontSize: 16, fontWeight: 400 }}>{contract?.metadata?.length} Members</Typography>
                            <CsvDownloadButton
                                // className="downloadBtn"
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    borderColor: "#FFF",
                                    borderRadius:"5px",
                                    borderWidth: 0,
                                    boxShadow: "3px 5px 4px rgb(27 43 65/5%), -3px -3px 8px rgba(201,75,50,.1)",
                                    color: "#b12f15",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    height: "40px",
                                    width: "40px",
                                    opacity: 1
                                }}
                                filename={`mint.csv`}
                                data={exportableData}>
                                    <img style={{ width: 40, height: 40, marginLeft: -6, marginTop: -1 }} src={exportBtn}/>
                            </CsvDownloadButton>
                        </Box>
                    </Toolbar>
                </Box>
                <Box mt={0.5}>
                    <TableContainer component={Box}>
                        <Table style={{ backgroundColor: '#FFF' }} aria-label="simple table">
                            <TableBody>
                                {
                                    contract && contract?.metadata?.map((metadata: any, index: number) => {
                                        return (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell>
                                                    <Box display="flex" flexDirection="row" alignItems="center">
                                                        <Typography>#{ metadata.id }</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box display="flex" flexDirection="row" alignItems="center">
                                                        <Avatar name={_get(_find(metadata?.attributes, (m: any) => m.trait_type === "Name"), 'value', '')} wallet={_get(_find(metadata?.attributes, (m: any) => m.trait_type === "Wallet Address/ENS Domain"), 'value', '')} />
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box>
                                                        <Typography style={{ fontSize: 14, fontWeight: 400, }}>{`${ isNaN(parseFloat(_get(_find(metadata.attributes, aa => aa.trait_type === 'Price'), 'value', 0))) ? 0 : parseFloat(`${_get(_find(metadata.attributes, aa => aa.trait_type === 'Price'), 'value', 0)}`)} ${contractToken?.label}`}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    {renderIcons(metadata?.attributes)}
                                                </TableCell>
                                                <TableCell>
                                                    <Box>
                                                        <Typography style={{ fontSize: 14, fontWeight: 400, }}>{
                                                             _get(_find(metadata.attributes, aa => aa.trait_type === "Discount Code"), "value", null) && _get(_find(metadata.attributes, aa => aa.trait_type === "Discount Code"), "value", null) !== '' ? 
                                                            `${_get(_find(metadata.attributes, aa => aa.trait_type === "Discount Code"), "value", '')}` : ''
                                                        }</Typography>
                                                    </Box>
                                                </TableCell>
                                                 {
                                                 contract?.contactDetail.filter((c:any) => ['email', 'discord', 'telegram', 'github'].indexOf(c) === -1).map((contact: string) => {
                                                        return (
                                                            <TableCell>
                                                                <Box>
                                                                    <Typography style={{ fontSize: 14, fontWeight: 400, }}>{`${_get(_find(metadata.attributes, aa => aa.trait_type === contact), 'value', '')}`}</Typography>
                                                                </Box>
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                                {/* <TableCell>
                                                    <Box>
                                                        <Typography style={{ fontSize: 14, fontWeight: 400, }}>{`${_get(_find(metadata.attributes, aa => aa.trait_type === 'Others'), 'value', '')}`}</Typography>
                                                    </Box>
                                                </TableCell> */}
                                                <TableCell>
                                                    <Box>
                                                        <Typography style={{ fontSize: 14, fontWeight: 400, }}>{moment.utc(metadata.createdAt).local().format('MM/DD') + " " + moment.utc(metadata.createdAt).local().format('HH:MM')}</Typography>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
            <Drawer
                PaperProps={{ style: { borderTopLeftRadius: 20, borderBottomLeftRadius: 20 } }}
                sx={{ zIndex: 99999 }}
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}>
                <Box sx={{ position: 'relative', width: 575, paddingBottom: '60px', borderRadius: '20px 0px 0px 20px' }}>
                    { gaslessConfigLoading && <Box style={{ position: 'fixed', display: 'flex', alignItems: 'center', justifyContent: 'center', maxHeight: '100vh', zIndex: 9999999, top: 0, left: 0, bottom: 0, right: 0, backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
                        <LeapFrog color='#C94B32' />
                    </Box> }
                    <IconButton sx={{ position: 'fixed', right: 32, top: 32 }} onClick={() => setShowDrawer(false)}>
                        <img src={CloseSVG} />
                    </IconButton>
                    <Box display="flex" flexDirection="column" my={6} alignItems="center">
                        <img src={MintSBTSvg} />
                        <Typography my={4} style={{ color: palette.primary.main, fontSize: '30px', fontWeight: 400 }}>Pass Token</Typography>
                        <Typography variant='subtitle2' sx={{ px: 12, fontSize: 16 }} textAlign="center" color="rgb(118, 128, 141)"> Launch '<span style={{ fontWeight: '700' }}>Soulbound Tokens</span> (SBTs)' with ease and streamline app permissions (eg. Discord, Notion, Github, and Snapshot). Don't worry, <span style={{ fontWeight: '700' }}>member data is always secure</span> with encryption on SBT.</Typography>
                    </Box>
                    <Box margin="0 auto" width={350}>
                        <Paper elevation={0} className={classes.paperDetails}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                               
                                { contract && contract?.chainId &&
                                <Box sx={{ mr: 2 }} onClick={() => window.open(`${_get(CHAIN_INFO, chainId).explorer}address/${contract.address}`)} style={{ cursor: 'pointer' }}>
                                    <img style={{ width: 24, marginLeft: 8, height: 24, objectFit: 'contain' }} src={_get(CHAIN_INFO, contract?.chainId).logoUrl} />
                                </Box> }
                                    
                                {/* <Typography className={classes.tokenName}>{contract?.token}</Typography> */}
                                <TextInput value={state?.symbol}
                                    error={errors['symbol']}
                                    helperText={errors['symbol']}
                                    onChange={(e: any) => {
                                        setErrors({})
                                        setState((prev: any) => { return { ...prev, symbol: e.target.value } })
                                    }}
                                    sx={{ my: 1 }} placeholder="LMDS" fullWidth
                                />
                            </Box>
                            {/* <Box display="flex" flexDirection="row" alignItems="center">
                                {contract?.tokenSupply && <Typography className={classes.tokenSupply}>{`X ${contract?.tokenSupply}`}</Typography>}
                            </Box> */}
                        </Paper>
                        <Box mt={0}>
                                <FormControl fullWidth>
                                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        {/* <Chip sx={{ mr: 1 }} className={classes.chip} size="small" label="Optional" /> */}
                                    </Box>
                                </FormControl>
                                <Box style={{ position: 'relative', width: 200 }}>
                                    { state?.logo && contract?.admin === user._id && <Box onClick={() => setState((prev: any) => { return { ...prev, logo: null } })} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', width: 24, borderRadius: 24, right: 8, top: 8, height: 24, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                                        <CloseIcon style={{ fontSize: 12 }}/> 
                                    </Box> }
                                    <Dropzone
                                        disabled={contract?.admin !== user._id}
                                        value={state?.logo}
                                        info={false}
                                        onUpload={(url: string) => {
                                            setState((prev: any) => { return { ...prev, logo: url } })
                                        }}
                                    />
                                    {
                                        errors['logo'] &&
                                        <Typography mt={-2} sx={{ color: '#e53935', fontSize: '11px', marginLeft: '14px' }}>{errors['logo']}</Typography>
                                    }
                                </Box>
                        </Box>
                        <Box my={3} mx={1}>
                            <Typography
                                style={{
                                    color: '#76808d',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    marginBottom: '10px'
                                }}
                            >Membership policy:</Typography>
                            <Box ml={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                <Switch
                                    disabled={contract?.admin !== user._id}
                                    onChange={(e: any) => {
                                        setState((prev: any) => {
                                            return {
                                                ...prev,
                                                whitelisted: !prev.whitelisted,
                                                whitelist: { members: [], discounts: [], inviteCodes: [] }
                                            }
                                        })
                                    }}
                                    checked={state?.whitelisted} label={`WHITELISTED ${members.length > 0 ? "(" + members.length + " members)" : ''}`} />
                                {state?.whitelisted && <XlsxUpload onComplete={handleInsertWallets} />}
                            </Box>
                        </Box>
                        <Box my={4} mx={1}>
                            <Typography
                                style={{
                                    color: '#76808d',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    marginBottom: '10px'
                                }}
                            >Priced:</Typography>
                            <Box ml={0} display="flex" flexDirection="column" justifyContent="space-between">
                                <Switch
                                    disabled={contract?.admin !== user._id}
                                    style={{ marginLeft: 1 }}
                                    onChange={(e: any) => {
                                        setState((prev: any) => {
                                            return {
                                                ...prev,
                                                priced: !prev.priced,
                                                treasury: !e.target.checked ? "0x0000000000000000000000000000000000000000" : contract?.treasury,
                                                price: {
                                                    ...prev.price, 
                                                    value: !e.target.checked ? 0 : contract?.mintPrice && contract?.mintPrice !== "" && parseFloat(contract?.mintPrice) > 0 ? parseFloat(contract?.mintPrice) : null
                                                }
                                            }
                                        })
                                    }}
                                    checked={state?.priced} label="" />
                                <Box sx={{ mt: 2 }}>
                                    {state?.priced &&
                                        <CurrencyInput
                                           disabled={contract?.admin !== user._id}
                                            value={_get(state, 'price.value')}
                                            onChange={(value: any) => {
                                                setState((prev: any) => { return { ...prev, price: { ...prev.price, value: value } } })
                                            }}
                                            options={tokens}
                                            dropDownvalue={_get(state, 'price.token')}
                                            onDropDownChange={(value: string) => {
                                                setState((prev: any) => { return { ...prev, price: { ...prev.price, token: value } } })
                                            }}
                                        />
                                    }
                                </Box>
                            </Box>
                            <Box sx={{ mt: 4 }}>
                                {state['priced'] &&
                                    <TextInput value={state?.treasury}
                                        disabled={contract?.admin !== user._id}
                                        error={errors['treasury']}
                                        helperText={errors['treasury']}
                                        onChange={(e: any) => {
                                            setState((prev: any) => { return { ...prev, treasury: e.target.value } })
                                        }}
                                        placeholder="Multi-sig Wallet address" sx={{ my: 1 }} fullWidth label="Multi-sig Wallet" />
                                }
                            </Box>
                         { state?.priced && +state?.price?.value > 0 && 
                            <Box>
                                <Box my={3}>
                                    <Typography
                                        style={{
                                            color: '#76808d',
                                            fontSize: '16px',
                                            fontWeight: 700,
                                            marginBottom: '10px'
                                        }}
                                    >Discount codes:</Typography>
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <TextInput value={discountPlaceholder?.code} onChange={(e: any) => setDiscountPlaceholder((prev: any) => { return { ...prev, code: e.target.value } })} placeholder="code" sx={{ mr: 1 }} fullWidth />
                                        <TextInput value={discountPlaceholder?.percentage} onChange={(e: any) => setDiscountPlaceholder((prev: any) => { return { ...prev, percentage: +e.target.value } })} inputProps={{ step: 1, min: 0, max: 100, type: 'number' }} placeholder="Percentage" sx={{ mr: 1 }} fullWidth />
                                        <IconButton onClick={() => handleInsertDiscountCode(discountPlaceholder)} sx={{ height: '52px !important', width: '52px !important' }}>
                                            <AddIcon color="primary" />
                                        </IconButton>
                                    </Box>
                                    {errors['code'] && <Typography style={{
                                        marginTop: 8,
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }} color="red">{ errors['code'] }</Typography> }
                                </Box>
                                { +contract?.version >= 1 && state?.discountCodes && state?.discountCodes.length > 0 && 
                                <Box>
                                    <List sx={{ maxHeight: 300, overflowY: 'auto', backgroundColor: '#EEE', borderRadius: 2 }} dense>
                                    {
                                            state?.discountCodes?.map((discount: any) => {
                                                return (
                                                    <ListItem
                                                    secondaryAction={<Box onClick={() => {
                                                        setState((prev: any) => {
                                                            return {
                                                                ...prev,
                                                                discountCodes: prev?.discountCodes?.filter((m:any) => m?.code !== discount?.code)
                                                            }
                                                        })
                                                    }} sx={{ cursor: 'pointer' }}><CloseIcon/></Box>}
                                                    >
                                                        <ListItemText sx={{ width: 250, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} primary={discount.code} />
                                                        <ListItemText sx={{ width: 250, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} primary={`${discount.percentage}%`} />
                                                    </ListItem>
                                                )
                                            })
                                    }
                                    </List>
                                </Box> }
                             </Box>
                            }
                        </Box>
                        { +contract?.version >= 2 &&
                        <Box my={4} mx={1}>
                            <Typography
                                style={{
                                    color: '#76808d',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    marginBottom: '10px'
                                }}
                            >Gasless:</Typography>
                            <Box ml={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                <Switch
                                    onChange={(e: any) => handleGaslessChange(e.target.checked) }
                                    checked={state?.gasless} label="" />
                                { mintEstimate && <Typography sx={{  }} style={{ fontSize: 11 }}>{ `${ mintEstimate ? "Estimated gas price ~" + (+mintEstimate).toFixed(4) + " " +  CHAIN_INFO[contract?.chainId]?.nativeCurrency?.symbol : '' }` }</Typography> }
                            </Box>
                            { state?.gasless && state?.gasConfig &&
                            <Box my={4}>
                            <Typography
                                style={{
                                    color: '#76808d',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    marginBottom: '10px'
                                }}
                                >Gas Balance:</Typography>
                                <Typography>{ parseFloat(gasDeposit).toFixed(4) } MATIC</Typography>
                            </Box>
                            }
                            { state?.gasless && state?.gasConfig &&
                            <Box my={4}>
                            <Typography
                                style={{
                                    color: '#76808d',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    marginBottom: '10px'
                                }}
                                >Deposit Amount</Typography>
                              <CurrencyInput
                                    disableCurrency
                                    value={_get(refillPrice, 'value')}
                                    onChange={(value: any) => {
                                        setRefillPrice((prev: any) => { return { ...prev.price, value: value  } })
                                    }}
                                    options={tokens}
                                    dropDownvalue={_get(refillPrice, 'token')}
                                    onDropDownChange={(value: string) => {
                                        setRefillPrice((prev: any) => { return { ...prev.price, token: value  } })
                                    }}
                                />
                                <Typography sx={{ mt: 2, mb: 1, opacity: 0.6 }}>Minimum Deposit Amount: 5 MATIC</Typography>
                                <Button sx={{ mt: 1 }} onClick={() => handleFillGas()} disabled={fillGasLoading} loading={fillGasLoading} variant="outlined" size="small">Fill gas</Button>
                                {fillGasError && <Typography style={{
                                    marginTop: 8,
                                    whiteSpace: 'nowrap',
                                    width: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }} color="red">{ fillGasError }</Typography> }
                            </Box>
                            }
                        </Box> }
                        <Box style={{ height: 4, width: 200, alignSelf: 'center', margin: '60px auto', backgroundColor: palette.primary.main }}></Box>
                        <Box className={classes.paperDetailsSocial}>
                            <Box>
                                <Typography variant="h6">Contact details</Typography>
                                <Typography variant="body2" className={clsx(classes.socialText, { fontStyle: 'normal !important' })}>Get certain member details could be useful for the smooth functioning of your organisation</Typography>
                                <Box my={3} mx={1}>
                                    <Switch
                                        checked={state?.contact.indexOf('email') > -1}
                                        onChange={() => handleContactChange('email')}
                                        label="Email" />
                                    <Typography variant="body2" className={classes.socialText}>Please select if you intend to use services such as Notion, Google Workspace and Github</Typography>
                                </Box>
                                <Box my={3} mx={1}>
                                    <Switch
                                        onChange={() => handleContactChange('discord')}
                                        checked={state?.contact.indexOf('discord') > -1}
                                        label="Discord user-id" />
                                    <Typography variant="body2" className={classes.socialText}>Please select if you intend to use access-controlled channels in Discord.</Typography>
                                </Box>
                                <Box my={3} mx={1}>
                                    <Switch
                                        onChange={() => handleContactChange('telegram')}
                                        checked={state?.contact.indexOf('telegram') > -1}
                                        label="Telegram user-id" />
                                    <Typography variant="body2" className={classes.socialText}>Please select if you intend to use access-controlled Telegram groups.</Typography>
                                </Box>
                                <Box my={3} mx={1}>
                                    <Switch
                                        onChange={() => handleContactChange('github')}
                                        checked={state?.contact.indexOf('github') > -1}
                                        label="Github user-id" />
                                    <Typography variant="body2" className={classes.socialText}>Please select if you intend to use access-controlled github.</Typography>
                                </Box>
                                {
                                        contract?.contactDetail?.filter((c:string) => ['email', 'discord', 'telegram', 'github'].indexOf(c) === -1).map((item:any, index:number) => (
                                            <Box my={3} mx={1} key={index}>
                                                <Switch
                                                    onChange={() => handleContactChange(item)}
                                                    checked={state?.contact.indexOf(item) > -1}
                                                    label={`${item}`}
                                                />
                                                <Typography variant="body2" className={classes.socialText}>{`Please select if you intend to use ${item}`}</Typography>
                                            </Box>
                                        ))
                                }
                            </Box>
                        </Box>
                        <Box display="flex" mt={4} flexDirection="row">
                            <Button onClick={() => setShowDrawer(false)} sx={{ mr: 1 }} fullWidth variant='outlined' size="small">Cancel</Button>
                            <Button disabled={updateContractLoading} loading={updateContractLoading} onClick={() => saveChanges()} sx={{ ml: 1 }} fullWidth variant='contained' size="small">Save</Button>
                        </Box>
                        {networkError && <Typography style={{ textAlign: 'center', color: 'red' }}>{networkError}</Typography>}
                    </Box>
                </Box>
            </Drawer>
        </Grid>
    )
}